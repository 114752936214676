<template>
  <div>
    <div v-show="showClick2PayPaymentIframe" class="c2p-wrapper with-c2p-iframe">
      <div class="click2payIframeWrapper">
        <iframe ref="click2payIframe" id="click2payIframe"></iframe>
      </div>
    </div>
    <form v-show="!showClick2PayPaymentIframe" ref="form" @submit.prevent="submit" v-form:form novalidate>
      <input type="hidden" id="paywayID" name="paywayID" :label="$t('paywall.form.payway.label')" v-model="paywayID" requiredPayway/>
      <!-- kanały płatności -->
      <fieldset ref="paywayElem" class="form-row" v-if="canShowPaywayList()"
                :class="{'is-error': isErrorForField('paywayID')}">
        <legend class="choose-bank sr-only">{{ $t('paywall.form.payway.label') }}</legend>
        <p role="alert" tabindex="0" v-if="isErrorForField('paywayID')" class="error"
           :aria-label="getFieldErrorAriaLabel('paywayID')">
          <span aria-hidden="true">{{ getFieldErrorText('paywayID') }}</span>
        </p>
        <payment-payway-list :payways="paywayGroup.payways"/>
      </fieldset>

      <!-- OTP -->
      <div class="form-row" v-if="isOtp">
        <div class="blikPayLaterLink" id="blikHelp" v-if="isOtpBlikPayLaterSelected">
          <a href="" @click.prevent="showBlikPayLaterModal($event)">{{
              $t('paywall.paywayGroup.blikPayLater.info')
            }}</a>
        </div>
        <div class="paykaLink" id="paykaHelp" v-if="isOtpPaykaSelected">
          <span v-if="isServiceTypeMwf">{{ $t('configuration.paywayGroups.otp.descriptionInfo1Mwf') }}</span>
          <span v-if="isServiceTypeEcommerce">{{ $t('configuration.paywayGroups.otp.descriptionInfo1Ecommerce') }}</span> <a href="" @click.prevent="showPaykaModal($event)">
          <span v-if="isServiceTypeMwf">{{ $t('configuration.paywayGroups.otp.descriptionInfo2Mwf') }}</span>
          <span v-if="isServiceTypeEcommerce">{{ $t('configuration.paywayGroups.otp.descriptionInfo2Ecommerce') }}</span>
        </a>
        </div>
      </div>

      <!-- Alior Raty -->
      <div class="form-row" v-if="isInstallments">
        <div class="aliorLink" id="aliorHelp" v-if="isInstallmentsSelected">
          {{ $t('configuration.paywayGroups.alior.descriptionInfo1') }}
          <a href="" @click.prevent="showAliorModal($event)">{{
              $t('configuration.paywayGroups.alior.descriptionInfo2')
            }}</a>
        </div>
      </div>

      <!-- Rekurencja -->
      <div class="form-row" v-if="isAuto && canShowCardsIframe" :class="{'is-error': isErrorForField('creditCard')}">
        <input type="hidden" id="creditCard" name="creditCard" :label="$t('paywall.form.card.iframe.title')" v-model="creditCard" requiredCreditCard/>
        <div class="loading-mask" v-if="!iframeLoaded">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
        <p role="alert" tabindex="0" v-if="isErrorForField('creditCard')" class="error" :aria-label="getFieldErrorAriaLabel('creditCard')">
          <span aria-hidden="true">{{ getFieldErrorText('creditCard') }}</span>
        </p>
        <iframe ref="iframe"
                :class="{'hidden': isCardIframeHidden}"
                id="iframeCards"
                :src="getCardIframeSrc()"
                @load="setCardIframeLoaded"
                :title="$t('paywall.form.card.iframe.title')">
        </iframe>
      </div>

      <!-- karty -->
      <div class="c2p-wrapper" v-if="isCard && canShowCardsIframe">
        <div class="c2p-loading" v-if="isClick2PayAvailable && isClick2PayLoading">
          <img src="/web/img/click2pay/loader.gif" alt="Loading">
          <div v-html="$t('paywall.form.click2pay.loaderText')"></div>
        </div>
        <div v-if="!isClick2PayLoading">
          <div v-if="isClick2PayAvailable">
            <click-to-pay-profile v-if="canShowClick2PayProfileInfo"/>
            <click-to-pay-login-form v-if="isClick2PayViewLoginForm"
                                     @resetCardWidget="resetCardWidget"
                                     @resetForm="resetForm"/>
            <click-to-pay-code-form v-if="isClick2PayViewCodeForm"/>
            <click-to-pay-card-selected v-if="isClick2PayViewCardSelected"/>
            <click-to-pay-card-select v-if="isClick2PayViewCardSelect"/>
            <click-to-pay-new-user v-if="isClick2PayViewNewUser"
                                   @cancelClick2PayProcess="cancelClick2PayProcess"
                                   @customerFormSubmited="submitClick2PayCheckout"/>
          </div>
          <div class="form-row" :class="{'is-error': isErrorForField('creditCard')}">
            <input type="hidden" id="creditCard" name="creditCard" :label="$t('paywall.form.card.iframe.title')" v-model="creditCard" requiredCreditCard/>
            <div class="loading-mask" v-if="!iframeLoaded">
              <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
              </svg>
            </div>
            <p role="alert" tabindex="0" v-if="isErrorForField('creditCard')" class="error" :aria-label="getFieldErrorAriaLabel('creditCard')">
              <span aria-hidden="true">{{ getFieldErrorText('creditCard') }}</span>
            </p>
            <iframe ref="iframe"
                    :class="{'hidden': isCardIframeHidden}"
                    id="iframeCards"
                    :src="getCardIframeSrc()"
                    @load="setCardIframeLoaded"
                    :title="$t('paywall.form.card.iframe.title')">
            </iframe>
          </div>
          <div v-if="isClick2PayAvailable && (isClick2PayViewNotRecognized || isClick2PayViewNewCard)">
            <div class="agreements" v-if="isCreditCardMastercard">
              <div>
                <img src="/web/img/click2pay/mastercard.svg" alt="" height="20">
              </div>
              <div class="form-row">
                <input type="checkbox"
                       id="saveCard"
                       name="saveCard"
                       @keydown.13.prevent
                       v-model="c2p.saveCard"/>
                <label for="saveCard">
                  <span>
                  {{ $t('paywall.form.click2pay.saveCard.text1') }}<br>
                  <span>
                    <span v-html="$t('paywall.form.click2pay.saveCard.text2')"></span>&nbsp;
                    <a href="" @click.prevent="showClick2PayModal" id="click2payHelp">{{$t('paywall.form.click2pay.saveCard.link')}}</a>
                  </span>
                </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- visa mobile -->
      <div class="form-row" v-if="isVisaMobile" :class="{'is-error': isErrorForField('visaMobile')}">
        <input type="hidden" id="visaMobile" name="visaMobile" v-model="visaMobile" requiredVisaMobile/>
        <div class="loading-mask" v-if="!iframeLoaded">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
        <p role="alert" tabindex="0" v-if="isErrorForField('visaMobile')" class="error"
           :aria-label="getFieldErrorAriaLabel('visaMobile')">
          <span aria-hidden="true">{{ getFieldErrorText('visaMobile') }}</span>
        </p>
        <iframe ref="iframe"
                id="iframeVisaMobile"
                :src="getVisaMobileIframeSrc()"
                @load="setVisaMobileIframeLoaded"
                :title="$t('paywall.form.visaMobile.iframe.title')">
        </iframe>
      </div>

      <!-- email -->
      <div class="form-row input-text-field" v-if="showEmail && !isClick2PayOnlyView" :class="{'is-error': isErrorForField('customerEmail')}">
        <label for="customerEmail" v-if="!isClick2PayProcessActive && !c2p.saveCard">{{$t('paywall.form.email.label') }}</label>
        <label for="customerEmail" v-if="isClick2PayProcessActive || c2p.saveCard">{{$t('paywall.form.click2pay.email.labelForClick2Pay') }}</label>
        <p role="alert" tabindex="0" v-if="isErrorForField('customerEmail')" class="error" :aria-label="getFieldErrorAriaLabel('customerEmail')">
          <span aria-hidden="true">{{ getFieldErrorText('customerEmail') }}</span>
        </p>
        <div class="input-text-wrapper" :class="{'is-error': isErrorForField('customerEmail')}">
          <input
            type="email"
            name="customerEmail"
            id="customerEmail"
            :placeholder="$t('paywall.form.email.label')"
            email
            required
            @keydown.13.prevent
            v-model.trim="customerEmail"/>
          <span class="input-flow-label">&nbsp;{{ $t('paywall.form.email.flowLabel') }}&nbsp;</span>
          <span class="input-flow-mark"></span>
        </div>
        <div class="email-info" v-if="!isInitialCustomerEmailSet">
          <p>{{ $t('paywall.form.email.tip') }}</p>
        </div>
      </div>

      <!-- dodatkowe wymagane pola generowane dynamicznie -->
      <div v-if="hasPaywayRequiredFields">
        <div v-for="(field, index) in getPaywayRequiredFields" :key="index">
          <div v-if="isRequiredParamFieldTypeText(field.type)">
            <div class="form-row input-text-field" :class="{'is-error': isErrorForField(field.name)}">
              <label :for="field.name">{{ getRequiredParamLabel(field.name) }}</label>
              <p role="alert" tabindex="0" v-if="isErrorForField(field.name)" class="error" :aria-label="getFieldErrorAriaLabel(field.name)">
                <span aria-hidden="true">{{ getFieldErrorText(field.name) }}</span>
              </p>
              <div class="input-text-wrapper" :class="{'is-error': isErrorForField(field.name)}">
                <input
                  :type="getRequiredParamFieldType(field.type)"
                  :name="field.name"
                  :id="field.name"
                  :placeholder="getRequiredParamPlaceholder(field.name)"
                  :pattern="field.pattern"
                  required
                  @keydown.13.prevent
                  v-model.trim="requiredParams[field.name]"
                />
                <span class="input-flow-label">&nbsp;{{ getRequiredParamFlowLabel(field.name) }}&nbsp;</span>
                <span class="input-flow-mark"></span>
              </div>
            </div>
          </div>
          <div v-if="isRequiredParamFieldTypeCheckbox(field.type)" class="agreements">
            <div class="form-row" :class="{'is-error': isErrorForField(field.name)}">
              <input type="checkbox"
                     :id="field.name"
                     :name="field.name"
                     @keydown.13.prevent
                     v-model="requiredParams[field.name]"
                     required
              />
              <label :for="field.name">
                <span v-if="isErrorForField(field.name)" class="error">
                  {{ getFieldErrorText(field.name) }}
                </span>
                <span>
                  <span class="required">* </span>
                  <span>{{ getRequiredParamLabel(field.name) }}</span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- zgody i oświadczenia -->
      <h3 v-if="canShowAgreementHeader && !isClick2PayOnlyView">{{ $t('paywall.form.terms.header') }}</h3>
      <div class="agreements" v-if="!isClick2PayOnlyView">
        <!-- zaznacz wszystkie -->
        <div class="form-row select-all" v-if="canShowCheckAll">
          <input type="checkbox" id="checkAll" name="checkAll" @keydown.13.prevent v-model="checkAll" :model-standalone="true"/>
          <label for="checkAll" class="bold">
            {{ $t('paywall.form.terms.selectAll') }}
          </label>
        </div>

        <!-- regulamin jako checkbox -->
        <div v-if="regulation && !isRegulationHidden">
          <!-- nowa obsługa regulaminów -->
          <div v-if="isPaymentRegulationsMapSet">
            <div class="form-row" :class="{'is-error': isErrorForField('regulationApproved')}">
              <input type="checkbox"
                     id="regulationApproved"
                     name="regulationApproved"
                     @keydown.13.prevent
                     v-model="regulationApproved"
                     requiredTerms/>
              <label for="regulationApproved">
                <span v-if="isErrorForField('regulationApproved')" class="error">
                  {{ getFieldErrorText('regulationApproved') }}
                </span>
                <span>
                  <span class="required">* </span>
                  <regulation-text v-if="isRegulationLabelSet(regulation)" :regulation="regulation"></regulation-text>
                  <!-- TODO: określić co w przypadku jeśli regulamin nie ma labela -->
                  <span v-if="!isRegulationLabelSet(regulation)"
                        v-html="$t('paywall.form.termsLabelDefault', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
                </span>
              </label>
            </div>
          </div>

          <!-- stara obsługa regulaminów -->
          <div v-if="!isPaymentRegulationsMapSet">
            <div class="form-row" :class="{'is-error': isErrorForField('regulationApproved')}">
              <input type="checkbox"
                     id="regulationApproved"
                     name="regulationApproved"
                     @keydown.13.prevent
                     v-model="regulationApproved"
                     requiredTerms/>
              <label for="regulationApproved">
                <span v-if="isErrorForField('regulationApproved')" class="error">
              {{ getFieldErrorText('regulationApproved') }}
            </span>
                <span>
                  <span class="required">* </span>
                  <regulation-text v-if="!isRegulationReccuring && isRegulationLabelSet(regulation)"
                                   :regulation="regulation"></regulation-text>
                  <span v-if="!isRegulationReccuring && !isRegulationLabelSet(regulation)"
                        v-html="$t('paywall.form.termsLabelDefault', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
                  <span v-if="isRegulationReccuring">
                    <regulation-text :regulation="regulation"></regulation-text>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <!-- zgoda marketingowa - BluePersona -->
        <div v-if="canShowMarketingAgreementList">
          <div class="form-row" v-for="agreement in marketingAgreementList" :key="agreement.code"
               :class="{'is-error': isErrorForField(agreement.code)}">
            <input type="checkbox"
                   :id="agreement.code"
                   :name="agreement.code"
                   @keydown.13.prevent
                   v-model="marketing[agreement.code]"
                   :requiredConsent="agreement.required"/>
            <label :for="agreement.code">
              <span v-if="isErrorForField(agreement.code)" class="error">
                {{ getFieldErrorText(agreement.code) }}
              </span>
              <span v-if="agreement.required" class="required">* </span>
              <span v-if="!showMore[agreement.code]" v-html="agreement.name"></span>
              <a class="show-more"
                 href=""
                 v-if="!showMore[agreement.code] && agreement.name !== agreement.content"
                 role="button"
                 :ref="agreement.code + '_moreButton'"
                 @keydown.13.stop="toggleElement(agreement.code)"
                 @keydown.32.prevent
                 @keyup.32="toggleElement(agreement.code)"
                 @click.prevent="toggleElement(agreement.code)"
                 :title="$t('paywall.form.linkShowMoreTitle')">
                {{ $t('paywall.form.linkMore') }}
              </a>
              <span v-if="showMore[agreement.code]">
                <span v-html="agreement.content"></span>
                <a class="show-more"
                   href=""
                   role="button"
                   :ref="agreement.code + '_lessButton'"
                   @keydown.13.stop="toggleElement(agreement.code)"
                   @keydown.32.prevent
                   @keyup.32="toggleElement(agreement.code)"
                   @click.prevent="toggleElement(agreement.code)"
                   :title="$t('paywall.form.linkShowLessTitle')">
                  {{ $t('paywall.form.linkLess') }}
                </a>
            </span>
            </label>
          </div>
        </div>

        <!-- regulamin jako oświadczenie -->
        <div v-if="regulation && isRegulationHidden">
          <!-- nowa obsługa regulaminów -->
          <div v-if="isPaymentRegulationsMapSet">
            <div class="form-row">
              <p class="elem-hidden-for-aria"
                 v-html="$t('paywall.form.mwfClauseARIA', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></p>
              <p aria-hidden="true" class="terms-clause">
                <regulation-text v-if="isRegulationLabelSet(regulation)" :regulation="regulation"></regulation-text>
                <!-- TODO: określić co w przypadku jeśli regulamin nie ma labela -->
                <span v-if="!isRegulationLabelSet(regulation)"
                      v-html="$t('paywall.form.termsLabelDefault', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
              </p>
            </div>
          </div>

          <!-- stara obsługa regulaminów -->
          <div v-if="!isPaymentRegulationsMapSet">
            <!-- klauzula dla nie MWF -->
            <div class="form-row" v-if="!isServiceTypeMwf">
              <p class="terms-clause" v-html="regulation.inputLabel"></p>
            </div>

            <!-- MWF klauzula -->
            <div class="form-row" v-if="isServiceTypeMwf">
              <p class="elem-hidden-for-aria"
                 v-html="$t('paywall.form.mwfClauseARIA', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></p>
              <p aria-hidden="true" class="terms-clause">
                <span
                  v-html="$t('paywall.form.mwfClauseLess', {regulationUrl: regulation.url, privacyPolicyUrl: privacyPolicyUrl})"></span>
                <a class="show-more"
                   href=""
                   v-if="!showMore['mwfTerms']"
                   role="button"
                   ref="mwfTerms_moreButton"
                   @keydown.13.stop="toggleElement('mwfTerms')"
                   @keydown.32.prevent
                   @keyup.32="toggleElement('mwfTerms')"
                   @click.prevent="toggleElement('mwfTerms')"
                   :title="$t('paywall.form.linkShowMoreTitle')">
                  {{ $t('paywall.form.linkMore') }}
                </a>
                <span v-if="showMwfTermsMore">
              {{ $t('paywall.form.mwfClauseMore') }}
              <a class="show-more"
                 href=""
                 role="button"
                 ref="mwfTerms_lessButton"
                 @keydown.13.stop="toggleElement('mwfTerms')"
                 @keydown.32.prevent
                 @keyup.32="toggleElement('mwfTerms')"
                 @click.prevent="toggleElement('mwfTerms')"
                 :title="$t('paywall.form.linkShowLessTitle')">
                {{ $t('paywall.form.linkLess') }}
              </a>
            </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="submit-wrapper" v-if="!isClick2PayOnlyView">
        <div>
          <button v-if="isPaywayWithStandardButton" class="btn" :class="{'inactive': !isAuto && isFormInvalid()}"
                  :disabled="isSubmitDisabled">
            <span>{{ getSubmitButtonText() }}</span>
          </button>
          <button v-if="isVisaMobilePay" class="btn visamobile" :class="{'inactive': isFormInvalid()}"
                  :disabled="isSubmitDisabled">
            <span>{{ $t('paywall.form.submitButton.visaMobile') }}</span>
          </button>
          <google-pay-payway :marketing="marketing" v-if="isGooglePay"></google-pay-payway>
          <apple-pay-button :marketing="marketing" v-if="isApplePay"></apple-pay-button>
          <visa-checkout-pay :marketing="marketing" v-if="isVisaCheckoutPay"></visa-checkout-pay>
        </div>
        <p v-if="canShowPaymentInfo()" v-html="$t('paywall.form.paymentInfo')"></p>
      </div>
    </form>
  </div>
</template>

<script>
import PaymentPaywayList from '@/base/components/Payment/Paywall/PaywayList'
import RegulationText from '@/base/components/Payment/Paywall/RegulationText.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PAYMENT_INIT_TYPE, PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import {
  AUTO_ID_LIST,
  CARD_ID_LIST,
  INSTALLMENT_TYPES, OTP_TYPES,
  PAYWAY_GROUPS_TYPES,
  VISA_MOBILE_TYPES,
  WALLET_TYPES,
  getRequiredParamLabel,
  getRequiredParamPlaceholder,
  getRequiredParamFlowLabel, getRequiredParamFieldType, isRequiredParamFieldTypeText, isRequiredParamFieldTypeCheckbox
} from '@/base/const/payway.const'
import { PAYWALL_MODE } from '@/base/const/paywall.const'
import { PaymentClass } from '@/base/class/payment.class'
import { ToolsClass } from '@/base/class/tools.class'
import {
  MATOMO_CUSTOM_DIMENSION,
  MATOMO_EVENT_TYPE,
  MATOMO_PAYMENT_TYPE_GROUP,
  MATOMO_USER_TYPE
} from '@/base/const/matomo.const'
import { MatomoClass } from '@/base/class/matomo.class'
import GooglePayPayway from '@/base/components/Payment/Paywall/Wallet/GooglePay'
import ApplePayButton from '@/base/components/Payment/Paywall/Wallet/ApplePay'
import VisaCheckoutPay from '@/base/components/Payment/Paywall/Wallet/VisaCheckoutPay'
import { DateClass } from '@/base/class/date.class'
import FormMixin from '@/base/mixins/form.mixin'
import FormIframeCardsMixin from '@/base/mixins/formIframeCards.mixin'
import FormIframeVisaMobile from '@/base/mixins/formIframeVisaMobile.mixin'
import { REGULATION_TYPE } from '@/base/const/regulation.const'
import { ORANGE_CONTEXT_LIST } from '@/base/const/context.const'
import { CLICK2PAY_VIEW } from '@/base/const/click2pay.const'
import ClickToPayLoginForm from '@/base/components/Payment/Paywall/ClickToPay/LoginForm.vue'
import ClickToPayCodeForm from '@/base/components/Payment/Paywall/ClickToPay/CodeForm.vue'
import ClickToPayCardSelected from '@/base/components/Payment/Paywall/ClickToPay/CardSelected.vue'
import ClickToPayCardSelect from '@/base/components/Payment/Paywall/ClickToPay/CardSelect.vue'
import ClickToPayNewUser from '@/base/components/Payment/Paywall/ClickToPay/NewUser.vue'
import ClickToPayProfile from '@/base/components/Payment/Paywall/ClickToPay/Profile.vue'
import { LANGUAGES_CODE_LIST } from '@/base/const/lang.const'
import { Raven } from 'vue-raven'

export default {
  name: 'PaywallForm',
  components: {
    ClickToPayProfile,
    ClickToPayNewUser,
    ClickToPayCardSelect,
    ClickToPayCardSelected,
    ClickToPayCodeForm,
    ClickToPayLoginForm,
    VisaCheckoutPay,
    ApplePayButton,
    GooglePayPayway,
    PaymentPaywayList,
    RegulationText
  },
  mixins: [
    FormMixin,
    FormIframeCardsMixin,
    FormIframeVisaMobile
  ],
  data () {
    return {
      requiredParams: {},
      marketing: {},
      widget: null,
      visaMobile: null,
      creditCard: null,
      isCreditCardMastercard: false,
      iframeLoaded: false,
      showMore: {},
      showReccuringTermsMore: false,
      showMwfTermsMore: false,
      showClick2PayPaymentIframe: false,
      c2p: {
        saveCard: false,
        dccChecked: false
      }
    }
  },
  watch: {
    selectedCard: function (value) {
      if (typeof value === 'object') {
        this.creditCard = true
      } else {
        this.creditCard = null
      }
    },
    isClick2PaySdkInitFinished: function () {
      if (this.canShowCardsIframe &&
        this.isCard &&
        this.isClick2PaySdkInitFinished &&
        this.isClick2PayAvailable &&
        !this.click2PayProcessActive) {
        this.SET_CLICK2PAY_LOADING(true)
        this.getClick2PayIdentityLookup().catch(() => {})
      }
    }
  },
  props: {
    paywayGroup: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      regulation: 'getPaymentFormRegulation',
      lang: 'getCurrentLanguage',
      paywayList: 'getPaywayList',
      marketingAgreementList: 'getMarketingAgreementList'
    }),
    ...mapState({
      language: state => state.lang.current,
      isClick2PayAvailable: state => state.click2pay.isProcessAvailable,
      isClick2PayLoading: state => state.click2pay.loading,
      isClick2PaySdkInitFinished: state => state.click2pay.isSdkInitFinished,
      selectedCard: state => state.click2pay.selectedCard,
      click2PayData: state => state.payment.paymentData.click2PayData,
      checkoutResult: state => state.click2pay.checkoutResult,
      isProcessing: state => state.payment.processing,
      showEmail: state => state.payment.paymentData.visibilityOptions
        ? state.payment.paymentData.visibilityOptions.showFrontEmail
        : null,
      isInitialCustomerEmailSet: state => state.payment.paymentData.additionalData && state.payment.paymentData.additionalData.customerEmail
        ? !!state.payment.paymentData.additionalData.customerEmail.length
        : false,
      showReturnToPaywaysButton: state => state.payment.paymentData.visibilityOptions
        ? state.payment.paymentData.visibilityOptions.showReturnToPaywaysButton
        : null,
      showReturnToShopButton: state => state.payment.paymentData.visibilityOptions
        ? state.payment.paymentData.visibilityOptions.showReturnToShopButton
        : null,
      isAuto: state => AUTO_ID_LIST.includes(state.payment.form.paywayID),
      isCard: state => CARD_ID_LIST.includes(state.payment.form.paywayID),
      isVisaMobilePay: state => state.payment.form.paywayID === VISA_MOBILE_TYPES.visaMobile.id,
      isVisaCheckoutPay: state => state.payment.form.paywayID === WALLET_TYPES.visaCheckout.id,
      isGooglePay: state => state.payment.form.paywayID === WALLET_TYPES.googlePay.id,
      isApplePay: state => state.payment.form.paywayID === WALLET_TYPES.applePay.id,
      isPbl: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.pbl,
      isWallet: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.wallet,
      isOtp: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.otp,
      isOtpBlikPayLaterSelected: state => state.payment.form.paywayID === OTP_TYPES.blikPayLater.id,
      isOtpPaykaSelected: state => state.payment.form.paywayID === OTP_TYPES.payka.id,
      isInstallments: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.installments,
      isInstallmentsSelected: state => state.payment.form.paywayID === INSTALLMENT_TYPES.alior.id,
      isAutoGroup: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.auto,
      isFastTransfer: state => state.payment.selectedPaywayGroupType === PAYWAY_GROUPS_TYPES.transfer,
      transaction: state => state.payment.paymentData.transaction,
      isServiceTypeEcommerce: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.ecommerce,
      isServiceTypeMwf: state => state.payment.paymentData.serviceType === PAYMENT_SERVICE_TYPE.mwf,
      serviceType: state => state.payment.paymentData.serviceType,
      serviceID: state => state.payment.paymentData.serviceID,
      amount: state => state.payment.paymentData.payAmount,
      currency: state => state.payment.paymentData.payAmountCurrency,
      isCardRecurringRegulationToShow: state => state.payment.paymentData.isCardRecurringRegulationToShow,
      recurringAction: state => state.payment.paymentData.recurringAction,
      selectedPaywayGroupType: state => state.payment.selectedPaywayGroupType,
      isPaywallModeChosenPayway: state => state.payment.paywallMode === PAYWALL_MODE.chosenPayway,
      merchantShortName: state => state.payment.paymentData.shortName,
      idTransRemote: state => state.payment.paymentData.transRemoteID,
      orderId: state => state.payment.paymentData.transaction.orderId,
      paywallStartTime: state => state.timer.paywallView,
      paymentToken: state => state.payment.paymentData.paymentToken,
      click2PayProcessActive: state => state.click2pay.isProcessActive,
      click2PayView: state => state.click2pay.view,
      isPaymentRegulationsMapSet: state => !!state.payment.paymentData.paywaysRegulations && typeof state.payment.paymentData.paywaysRegulations === 'object',
      paywaysLanguageData: function (state) {
        return state.payment.paywaysLanguageData !== null ? state.payment.paywaysLanguageData[this.paywayID] : null
      }
    }),
    isClick2PayProcessActive: function () {
      return this.click2PayProcessActive
    },
    isClick2PayViewNotRecognized: function () {
      return this.click2PayView === CLICK2PAY_VIEW.notRecognized
    },
    isClick2PayViewLoginForm: function () {
      return this.click2PayView === CLICK2PAY_VIEW.loginForm
    },
    isClick2PayViewCodeForm: function () {
      return this.click2PayView === CLICK2PAY_VIEW.codeForm
    },
    isClick2PayViewCardSelected: function () {
      return this.click2PayView === CLICK2PAY_VIEW.cardSelected
    },
    isClick2PayViewCardSelect: function () {
      return this.click2PayView === CLICK2PAY_VIEW.cardSelect
    },
    isClick2PayViewNewCard: function () {
      return this.click2PayView === CLICK2PAY_VIEW.newCard
    },
    isClick2PayViewDcc: function () {
      return this.click2PayView === CLICK2PAY_VIEW.dcc
    },
    isClick2PayViewNewUser: function () {
      return this.click2PayView === CLICK2PAY_VIEW.newUser
    },
    isClick2PayOnlyView: function () {
      if (!this.isCard) {
        return false
      }
      const click2payInnerViews = [
        CLICK2PAY_VIEW.newUser,
        CLICK2PAY_VIEW.cardSelect,
        CLICK2PAY_VIEW.loginForm,
        CLICK2PAY_VIEW.codeForm
      ]
      return click2payInnerViews.includes(this.click2PayView)
    },
    activePayway: function () {
      if (!this.paywayID) {
        return null
      }
      return this.paywayList.find(payway => payway.id === this.paywayID)
    },
    privacyPolicyUrl: function () {
      return PaymentClass.getPrivacyPolicyLinkByLang(this.lang)
    },
    isRegulationHidden: function () {
      if (this.isPaymentRegulationsMapSet) {
        if (!this.regulation) {
          return true
        }
        return this.regulation.checkedAndHidden
      } else {
        const regulationType = this.activePayway?.recurring ? REGULATION_TYPE.recurring : REGULATION_TYPE.main
        return regulationType === REGULATION_TYPE.main && PaymentClass.isRegulationHidden(this.serviceType)
      }
    },
    isRegulationReccuring: function () {
      return this.regulation && this.regulation.type === REGULATION_TYPE.recurring
    },
    isPaywayTypePis: function () {
      return PaymentClass.isPaywayTypePis(this.paywayID, this.paywayList)
    },
    isPaywayWithStandardButton: function () {
      return !this.isGooglePay && !this.isApplePay && !this.isVisaMobilePay && !this.isVisaCheckoutPay
    },
    canShowMarketingAgreementList: function () {
      return !(this.$VUE_HIDE_MARKETING && this.$VUE_HIDE_MARKETING === true) && this.marketingAgreementList.length > 0
    },
    canShowAgreementHeader: function () {
      if (this.isRegulationHidden) {
        return this.canShowMarketingAgreementList
      }
      return !!this.regulation || this.canShowMarketingAgreementList
    },
    canShowCheckAll: function () {
      if (this.isRegulationHidden) {
        return this.canShowMarketingAgreementList
      }
      return !!this.regulation || this.canShowMarketingAgreementList
    },
    isCardIframeHidden: function () {
      if (!this.isCard) {
        return false
      }
      return !(this.isClick2PayViewNotRecognized || this.isClick2PayViewNewCard || this.isClick2PayViewDcc)
    },
    canShowClick2PayProfileInfo: function () {
      return this.isClick2PayViewNotRecognized ||
        this.isClick2PayViewNewCard ||
        this.isClick2PayViewCardSelected ||
        this.isClick2PayViewDcc ||
        this.isClick2PayViewLoginForm
    },
    canShowCardsIframe: function () {
      return this.activePayway && !this.activePayway.redirectToCards
    },
    isVisaMobile: function () {
      return this.paywayID === VISA_MOBILE_TYPES.visaMobile.id
    },
    isSubmitDisabled: function () {
      return this.isProcessing || ((this.isCard || this.isVisaMobilePay) && !this.iframeLoaded)
    },
    hasPaywayRequiredFields: function () {
      return this.activePayway?.requiredParams?.length > 0
    },
    getPaywayRequiredFields: function () {
      return this.activePayway?.requiredParams
    },
    paywayID: {
      get () {
        return this.$store.state.payment.form.paywayID
      },
      set () {
      }
    },
    regulationApproved: {
      get () {
        return this.$store.state.payment.form.regulationApproved
      },
      set (value) {
        if (value) {
          this.SET_PAYMENT_FORM_REGULATION_APPROVED(true)
          if (this.isRegulationReccuring) {
            this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER('ACCEPTED')
          }
        } else {
          this.SET_PAYMENT_FORM_REGULATION_APPROVED(false)
          this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER(null)
        }
      }
    },
    checkAll: {
      get () {
        const flags = []
        if (!this.isRegulationHidden && this.regulation) {
          flags.push(!!this.$store.state.payment.form.regulationApproved)
        }
        Object.keys(this.marketing).forEach(key => {
          flags.push(!!this.marketing[key])
        })
        return flags.reduce((acc, value) => {
          return acc && value
        }, true)
      },
      set (value) {
        if (!this.isRegulationHidden && this.regulation) {
          if (value) {
            this.SET_PAYMENT_FORM_REGULATION_APPROVED(true)
            if (this.isRegulationReccuring) {
              this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER('ACCEPTED')
            }
          } else {
            this.SET_PAYMENT_FORM_REGULATION_APPROVED(false)
            this.SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER(null)
          }
        }
        Object.keys(this.marketing).forEach(key => {
          this.marketing[key] = value
        })
      }
    },
    customerEmail: {
      get () {
        return this.$store.state.payment.form.customerEmail
      },
      set (value) {
        this.SET_PAYMENT_FORM_EMAIL(value)
      }
    }
  },
  methods: {
    isRequiredParamFieldTypeCheckbox,
    isRequiredParamFieldTypeText,
    getRequiredParamFieldType,
    getRequiredParamFlowLabel,
    getRequiredParamLabel,
    getRequiredParamPlaceholder,
    ...mapMutations([
      'SET_PAYMENT_LOADING',
      'SET_CLICK2PAY_LOADING',
      'SET_PAYMENT_FORM_EMAIL',
      'SET_PAYMENT_FORM_REGULATION_APPROVED',
      'SET_PAYMENT_FORM_RECURRING_ACCEPTANCE_STATE_AFTER',
      'SET_PAYMENT_FORM_WALLET_TYPE',
      'SET_ERROR_DATA',
      'SET_TIMER_FORM_SUBMITED',
      'SET_TIMER_TRX_UPDATE_START',
      'SET_TIMER_TRX_UPDATE_LOADED',
      'SET_CLICK2PAY_VIEW',
      'SET_CLICK2PAY_TRANSACTION_AMOUNT',
      'SET_CLICK2PAY_TRANSACTION_CURRENCY',
      'SET_CLICK2PAY_IS_PROCESS_AVAILABLE',
      'SET_CLICK2PAY_PROCESS_RESET'
    ]),
    ...mapActions([
      'sendPaymentUpdateTrx',
      'logTimerStats',
      'changePaywallMode',
      'disableSessionTimer',
      'getClick2PayEnrollCard',
      'getClick2PayCheckout',
      'getClick2PayIdentityLookup'
    ]),
    goToClick2PayCardSelected () {
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.cardSelected)
    },
    goToClick2PayNewUser () {
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.newUser)
    },
    goToClick2PayDcc () {
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.dcc)
    },
    isRegulationLabelSet (regulation) {
      return !!regulation.inputLabel && String(regulation.inputLabel).length
    },
    showBlikPayLaterModal (event) {
      event.stopPropagation()
      const message = {
        focusReturnElementId: 'blikHelp',
        component: 'BlikPayLaterModal',
        options: {
          size: 'big',
          hideButton: true,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    showAliorModal (event) {
      event.preventDefault()
      const message = {
        focusReturnElementId: 'aliorHelp',
        component: 'AliorModal',
        options: {
          size: 'big',
          hideButton: true,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    showPaykaModal (event) {
      event.preventDefault()
      const message = {
        focusReturnElementId: 'paykaHelp',
        component: 'PaykaModal',
        options: {
          size: 'big',
          hideButton: true,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    showClick2PayModal (event) {
      event.preventDefault()
      const message = {
        focusReturnElementId: 'click2payHelp',
        component: 'Click2PayModal',
        options: {
          size: 'medium',
          hideButton: true,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    showClick2PayProcessErrorModal () {
      const message = {
        focusReturnElementId: 'paywayID',
        component: 'Click2PayPrcessErrorModal',
        options: {
          size: 'medium',
          hideButton: false,
          showClose: true
        }
      }
      this.addModalInfo(message)
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    toggleElement (name) {
      const elementValue = !!this.showMore[name]
      this.showMore[name] = !elementValue
      this.$forceUpdate()
      if (!elementValue) {
        this.$nextTick(() => {
          this.$refs[name + '_lessButton'][0].focus()
        })
      } else {
        this.$nextTick(() => {
          this.$refs[name + '_moreButton'][0].focus()
        })
      }
    },
    canShowPaywayList () {
      return (this.isPbl || this.isWallet || this.isFastTransfer || this.isAutoGroup || this.isOtp) && !AUTO_ID_LIST.includes(this.paywayID)
    },
    canShowPaymentInfo () {
      return this.isPbl
    },
    changeTransactionDataByDcc (dccData) {
      this.SET_CLICK2PAY_TRANSACTION_AMOUNT(dccData.amount)
      this.SET_CLICK2PAY_TRANSACTION_CURRENCY(dccData.currency)
    },
    submitClick2PayEncryptedCard (encryptedCard) {
      const data = {
        'encryptedCard': encryptedCard
      }
      this.getClick2PayEnrollCard(data)
        .then(() => {
          if (!this.click2PayProcessActive) {
            this.goToClick2PayNewUser()
          } else {
            this.widget && this.submitClick2PayCheckout()
          }
        })
        .catch(error => {
          if (ToolsClass.isDebugEnabled()) {
            console.log('submitClick2PayEncryptedCard error', error)
          }
          Raven.captureMessage('Form submitClick2PayEncryptedCard error', {
            extra: error
          })
        })
    },
    resetForm () {
      this.form.resetForm()
    },
    resetCardWidget () {
      setTimeout(() => {
        this.widget && this.widget.reset()
        this.creditCard = null
        this.resetForm()
        this.c2p.saveCard = false
        this.c2p.dccChecked = false
      }, 50)
    },
    resetClick2PayProcess () {
      this.resetCardWidget()
      this.showClick2PayProcessErrorModal()
      this.SET_CLICK2PAY_IS_PROCESS_AVAILABLE(false)
      this.SET_CLICK2PAY_PROCESS_RESET()
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.notRecognized)
    },
    cancelClick2PayProcess () {
      this.widget && this.widget.resetCheckoutProcess()
      this.c2p.saveCard = false
      this.submit()
    },
    checkDccForSelectedCard () {
      this.widget.checkCheckoutDcc(this.selectedCard)
    },
    resolveCheckDccForSelectedCard (response) {
      this.c2p.dccChecked = true
      if (response === 'DCC') {
        this.goToClick2PayDcc()
        ToolsClass.scrollToElementTop(document.querySelector('header'))
        return
      }
      this.submitClick2PayCheckout()
    },
    submitClick2PayCheckout () {
      ToolsClass.scrollToElementTop(document.querySelector('header'))
      this.showClick2PayPaymentIframe = true
      this.getClick2PayCheckout(LANGUAGES_CODE_LIST[this.language])
        .then(() => {
          this.checkoutResult.dpaId = this.click2PayData.srciDpaId
          this.widget && this.widget.sendCheckout(this.checkoutResult)
        })
        .catch(() => {
          this.showClick2PayPaymentIframe = false
          this.resetClick2PayProcess()
        })
    },
    submit (e, token, forceVisaMobile = false) {
      if (!forceVisaMobile) {
        if (this.isSubmitDisabled) {
          return
        }
        if (this.form.invalid) {
          this.scrollToError()
          return
        }
      }

      if (this.isCard) {
        ToolsClass.scrollToElementTop(document.querySelector('iframe'), -50)
        if (!token) {
          // użycie zapisanej w C2P karty
          if (this.isClick2PayProcessActive && this.selectedCard) {
            this.submitClick2PayCheckout()
          } else {
            if (!this.c2p.saveCard) {
              this.widget && this.widget.sendForm()
            } else {
              this.widget && this.widget.encryptCard()
            }
          }
          return
        }
      }

      if (this.isAuto && !this.activePayway.redirectToCards) {
        ToolsClass.scrollToElementTop(document.querySelector('iframe'), -50)
        if (!token) {
          this.widget && this.widget.sendForm()
          return
        }
      }

      if (this.isVisaMobile) {
        ToolsClass.scrollToElementTop(document.querySelector('iframe'), -50)
        if (!token) {
          this.visaWidget && this.visaWidget.sendForm()
          return
        }
      }

      ToolsClass.scrollToElementTop(document.querySelector('header'))
      this.SET_TIMER_FORM_SUBMITED(DateClass.getCurrentTimestamp())

      if (this.isCard || this.isAuto) {
        this.SET_PAYMENT_FORM_WALLET_TYPE('WIDGET')
      } else {
        this.SET_PAYMENT_FORM_WALLET_TYPE(null)
      }

      MatomoClass.sendCustomDimension(MATOMO_CUSTOM_DIMENSION.sid, this.serviceID)
      MatomoClass.sendCustomDimension(MATOMO_CUSTOM_DIMENSION.paymentTypeGroup, MATOMO_PAYMENT_TYPE_GROUP[this.selectedPaywayGroupType])
      MatomoClass.sendCustomDimension(MATOMO_CUSTOM_DIMENSION.paymentTypeChannel, this.paywayID)
      MatomoClass.sendCustomDimension(MATOMO_CUSTOM_DIMENSION.userType, MATOMO_USER_TYPE.direct)

      const event = [
        MATOMO_EVENT_TYPE.trackEcommerceOrder,
        this.transaction.orderId,
        this.transaction.payAmount
      ]
      MatomoClass.sendEvent(event)
      this.disableSessionTimer()
      this.SET_TIMER_TRX_UPDATE_START(DateClass.getCurrentTimestamp())
      const data = {
        paymentToken: token,
        marketing: this.marketing,
        requiredParams: this.requiredParams
      }
      this.sendPaymentUpdateTrx(data).finally(() => {
        this.SET_TIMER_TRX_UPDATE_LOADED(DateClass.getCurrentTimestamp())
        this.logTimerStats()
      }).catch(error => {
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
    },
    sendToken (token) {
      this.submit(null, token)
    },
    getSubmitButtonText () {
      if (this.activePayway && this.paywaysLanguageData) {
        return this.paywaysLanguageData.buttonTitle
      }
      if (this.c2p.saveCard) {
        if (this.isServiceTypeMwf) {
          return this.$t('paywall.form.submitButton.c2pAccept')
        }
        if (this.isClick2PayProcessActive) {
          return this.$t('paywall.form.submitButton.c2pSaveAndPay')
        }
        return this.$t('paywall.form.submitButton.c2pNext')
      }
      if (this.isAuto) {
        if (ORANGE_CONTEXT_LIST.includes(this.$VUE_CONTEXT_NAME)) {
          return this.$t('paywall.form.submitButton.orange')
        }
        if ([PAYMENT_INIT_TYPE.initWithRefund, PAYMENT_INIT_TYPE.initWithPayment].includes(this.recurringAction)) {
          if (this.isServiceTypeMwf && !this.activePayway.redirectToCards) {
            return this.$t('paywall.form.submitButton.mwf')
          }
          return this.$t('paywall.form.submitButton.autoPayment')
        }
        if (this.isServiceTypeMwf && !this.activePayway.redirectToCards) {
          return this.$t('paywall.form.submitButton.mwf')
        }
        return this.$t('paywall.form.submitButton.auto')
      }
      if (this.isFastTransfer) {
        return this.$t('paywall.form.submitButton.fastTransfer')
      }
      if (this.isPaywayTypePis) {
        return this.$t('paywall.form.submitButton.pis')
      }
      if (this.isServiceTypeMwf) {
        return this.$t('paywall.form.submitButton.mwf')
      }
      return this.$t('paywall.form.submitButton.other')
    },
    setPaywallModeDefault () {
      this.changePaywallMode(PAYWALL_MODE.main)
    }
  },
  mounted () {
    this.marketingAgreementList.forEach(agreement => {
      this.$set(this.marketing, agreement.code, false)
      this.$set(this.showMore, agreement.code, false)
    })
    if (this.isVisaMobilePay && this.paymentToken && this.paymentToken.length) {
      this.submit(null, this.paymentToken, true)
    }
    if (this.canShowCardsIframe &&
      this.isCard &&
      this.isClick2PaySdkInitFinished &&
      this.isClick2PayAvailable) {
      if (!this.click2PayProcessActive) {
        this.SET_CLICK2PAY_LOADING(true)
        this.getClick2PayIdentityLookup().catch(() => {
        })
      }
      if (this.isClick2PayViewCardSelected) {
        this.creditCard = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";

.elem-hidden-for-aria {
  font-size: 0;
  line-height: 0;
  background-color: transparent;

  a.form-text-link {
    color: transparent;
    padding: 0;
  }
}

iframe {
  height: 520px;
  border: none;
  width: 100%;

  @media (min-width: 338px) {
    height: 430px;
  }
  @media (min-width: 440px) {
    height: 440px;
  }
  @media (min-width: 472px) {
    height: 372px;
  }

  &.hidden {
    display: none;
  }
}

.c2p-wrapper {
  position: relative;
  min-height: 140px;

  .c2p-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 150px;
    }

    div {
      font-size: 1.4rem;
      text-align: center;
      padding-bottom: 40px;
    }
  }

  .click2payIframeWrapper {
    display: none;
  }

  &.with-c2p-iframe {
    height: 770px;
    padding-bottom: 20px;

    .click2payIframeWrapper {
      display: block;
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 770px;
      background-color: #ffffff;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .agreements {
    label {
      span {
        font-size: 12px;

        span {
          font-size: 14px;

          a {
            font-size: 12px;
            color: var(--color-contrast-b2b);
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
